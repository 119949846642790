export interface DateWiseData {
	yesterday_count: number;
	today_count: number;
}

export interface StatusCounter {
	accepted: DateWiseData;
	in_queue: DateWiseData;
	rejected: DateWiseData;
	escalated: DateWiseData;
}

export interface ProductCounter {
	pra: DateWiseData;
	medium: DateWiseData;
	lite_a: DateWiseData;
	lite_b: DateWiseData;
}

export interface UserSLAData {
	avg_qc: DateWiseData;
	pool1_qc: DateWiseData;
	pool2_qc: DateWiseData;
	ecad: DateWiseData;
}

export interface ProductSLAData {
	pra: DateWiseData;
	lite_a: DateWiseData;
	lite_b: DateWiseData;
	medium: DateWiseData;
}

export interface SlaData {
	user_wise_sla: UserSLAData;
	product_wise_sla: ProductSLAData;
}

export interface ProductSlaData {
	pra: DateWiseData;
	medium: DateWiseData;
	lite_a: DateWiseData;
	lite_b: DateWiseData;
}

export interface StatusCountsByProduct {
	all_product: StatusCounter;
	pra: StatusCounter;
	lite_a: StatusCounter;
	medium: StatusCounter;
	lite_b: StatusCounter;
}

export interface StatusCountsByQc {
	all: StatusCountsByProduct;
	qc_pool1: StatusCountsByProduct;
	qc_pool2: StatusCountsByProduct;
}

export interface QcStatusCount {
	logged_in_count: number;
	online_count: number;
	offline_count: number;
	in_queue_count: number;
	max_in_queue_time: number;
}

export interface DashboardData {
	status_counts: StatusCountsByQc;
	product_counts: ProductCounter;
	sla: SlaData;
	qc_status_data: QcStatusCount;
}

export enum UserFiltersType {
	all_user = 'All User',
	qc_pool_1 = 'QC Pool 1',
	qc_pool_2 = 'QC Pool 2',
}
