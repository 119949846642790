/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */

import {useLocation} from 'react-router-dom';
import {useAuth} from '../../../modules/auth';
import {range} from '../../helpers';
import PageSelector from './PageSelector';
import RowsPerPage from './RowsPerPage';
import {PaginationProps} from './Types';
import rightvec from '../../../../assets/images/application/rightArrow.svg';

export default function Pagination({meta, links, changeItemPerPage, setCurrentPage}: PaginationProps) {
	const {currentUserInfo} = useAuth();
	const isCustomerSupportPage = useLocation().pathname === '/applications/customer-support';

	if (!meta || !links) return null;

	const getFirstLength = (currentPage: number) => {
		if (currentPage < 3) return 3;
		if (currentPage === 3) return 4;
		return 1;
	};

	const showMiddleNumbers = (currentPage: number, lastPage: number) => {
		return !(currentPage <= 3 || currentPage >= lastPage - 2);
	};

	const getLastLength = (currentPage: number, lastPage: number) => {
		if (currentPage === lastPage - 2) return 4;
		if (currentPage > lastPage - 2) return 3;
		return 1;
	};

	const getFirstRange = () => {
		return range(getFirstLength(meta.current_page));
	};
	const getLastRange = () => {
		const length = getLastLength(meta.current_page, meta.last_page);

		return range(length, meta.last_page - length + 1);
	};

	const getIthPage = (i: number) => {
		return (
			<PageSelector
				key={`${i}`}
				text={`${i}`}
				isActive={meta.current_page === i}
				onClick={() => {
					setCurrentPage(i);
				}}
			/>
		);
	};

	return (
		<div className='d-flex justify-content-between'>
			<RowsPerPage meta={meta} changeItemPerPage={changeItemPerPage} />

			<div id='kt_table_users_paginate'>
				{!isCustomerSupportPage ? (
					<ul className='pagination'>
						<PageSelector
							text='&lt;'
							isPrevious
							isDisabled={links.prev === null}
							onClick={() => {
								setCurrentPage(meta.current_page - 1);
							}}
						/>

						{meta.last_page < 7 && range(meta.last_page).map((i) => getIthPage(i))}

						{meta.last_page >= 7 && (
							<>
								{getFirstRange().map((i) => getIthPage(i))}

								<li className='mx-4' style={{marginLeft: '0.25rem', marginTop: '0.5rem'}}>
									<i className='fa-solid fa-ellipsis' />
								</li>

								{showMiddleNumbers(meta.current_page, meta.last_page) && (
									<>
										{range(3, meta.current_page - 1).map((i) => getIthPage(i))}
										<li className='page-item'>...</li>
									</>
								)}

								{getLastRange().map((i) => getIthPage(i))}
							</>
						)}

						<PageSelector
							text='&gt;'
							isNext
							isDisabled={links.next === null}
							onClick={() => {
								setCurrentPage(meta.current_page + 1);
							}}
						/>
					</ul>
				) : (
					<div className='d-flex gap-2'>
						<button
							disabled={links.prev === null}
							onClick={() => {
								setCurrentPage(meta.current_page - 1);
							}}
							className='btn  btn-primary'
						>
							&lt;
						</button>
						<button
							disabled={links.next === null}
							onClick={() => {
								setCurrentPage(meta.current_page + 1);
							}}
							className='btn  btn-primary'
						>
							&gt;
						</button>
					</div>
				)}
			</div>
		</div>
	);
}
