/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */

import {PaginationMeta} from './Types';

interface Props {
	meta: PaginationMeta;
	changeItemPerPage: (count: number) => void;
}

export default function RowsPerPage({meta, changeItemPerPage}: Props) {
	const options = [2, 10, 20, 50, 100];

	return (
		<div className='d-flex justify-content-start align-items-center fw-semibold fs-6' style={{color: '#5E6278'}}>
			<div>Showing</div>
			<div className='dropdown p-2'>
				<button
					className='btn btn-light dropdown-toggle p-2'
					type='button'
					id='dropdownMenuButton1'
					data-bs-toggle='dropdown'
					aria-expanded='false'
				>
					{meta.per_page}
				</button>
				<ul className='dropdown-menu fw-semibold fs-6' aria-labelledby='dropdownMenuButton1' style={{minWidth: '50px'}}>
					{options.map((v) => (
						<li key={v}>
							<a
								className='dropdown-item'
								href='#'
								onClick={() => {
									changeItemPerPage(v);
								}}
							>
								{v}
							</a>
						</li>
					))}
				</ul>
			</div>{' '}
			<div>of {meta.total} entries</div>
		</div>
	);
}
