import {ReactNode} from 'react';
import {WithChildren} from '../../../../_metronic/helpers';

interface Props {
	leftHeader: ReactNode;
	rightHeader: ReactNode;
	extraClasses?: string;
}

export default function WhiteCard({leftHeader, rightHeader, extraClasses = '', children}: Props & WithChildren) {
	return (
		<div
			className={`card card-flush mb-5 mb-xl-10 p-7 ${extraClasses}`}
			style={{
				backgroundColor: '#FFFFFF',
			}}
		>
			<div className='d-flex flex-column'>
				<div className='d-flex justify-content-between align-items-center mb-5'>
					{leftHeader}
					{rightHeader}
				</div>
				<div>{children}</div>
			</div>
		</div>
	);
}
