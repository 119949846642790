/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl';
import ApplicationsSVG from '../../../../../../assets/images/layout/applications.svg';
import DashboardSVG from '../../../../../../assets/images/layout/dashboard.svg';
import LogsSVG from '../../../../../../assets/images/layout/logs.svg';
import ReportSVG from '../../../../../../assets/images/layout/report.svg';
import SettingsSVG from '../../../../../../assets/images/layout/settings.svg';
import UsersSVG from '../../../../../../assets/images/layout/users.svg';
import {useAuth} from '../../../../../modules/auth';
import {SidebarMenuItem} from './SidebarMenuItem';
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub';

const SidebarMenuMain = () => {
	const intl = useIntl();
	const {currentUserInfo} = useAuth();

	return (
		<>
			{currentUserInfo?.can_read_dashboard && (
				<SidebarMenuItem
					to='/dashboard'
					icon={DashboardSVG}
					title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
					fontIcon='bi-speedometer2'
				/>
			)}
			{(currentUserInfo?.can_read_application || currentUserInfo?.can_read_queue_application) && (
				<SidebarMenuItemWithSub
					to='/crafted/accounts'
					title='Applications'
					icon={ApplicationsSVG}
					fontIcon='bi-speedometer2'
				>
					{currentUserInfo?.can_read_all_applications && (
						<SidebarMenuItem to='/applications/all' title='All Applications' hasBullet />
					)}
					{currentUserInfo?.can_read_queue_application && (
						<SidebarMenuItem to='/applications/in-queue' title='Unassigned Application' hasBullet />
					)}
					{currentUserInfo?.can_retry_cps_request && (
						<SidebarMenuItem to='/applications/cps-retriable' title='CPS Retriable' hasBullet />
					)}
					{currentUserInfo?.can_read_assigned_applications && (
						<SidebarMenuItem to='/applications/assigned-to-me' title='Assigned to me' hasBullet />
					)}
					{currentUserInfo?.can_read_application_history && (
						<SidebarMenuItem to='/applications/history' title='History' hasBullet />
					)}
				</SidebarMenuItemWithSub>
			)}

			{currentUserInfo?.can_read_report && (
				<SidebarMenuItem to='/ekyc-reports' icon={ReportSVG} title='Reports' fontIcon='bi-speedometer2' />
			)}

			{currentUserInfo?.can_read_log && (
				<SidebarMenuItem to='/logs' icon={LogsSVG} title='Logs' fontIcon='bi-speedometer2' />
			)}

			{currentUserInfo?.can_access_cs_portal && (
				<SidebarMenuItem
					to='/applications/customer-support'
					icon={ApplicationsSVG}
					title='Customer Support'
					fontIcon='bi-speedometer2'
				/>
			)}

			{(currentUserInfo?.can_read_configuration ||
				currentUserInfo?.can_read_reasons ||
				currentUserInfo?.can_read_cps_configuration) && (
				<SidebarMenuItemWithSub to='/crafted/accounts' title='Settings' icon={SettingsSVG} fontIcon='bi-speedometer2'>
					{currentUserInfo.can_read_configuration && (
						<SidebarMenuItem to='cms/configurations' title='Configurations' hasBullet />
					)}

					{currentUserInfo?.can_read_cps_configuration && (
						<SidebarMenuItem to='cms/cps-configurations' title='CPS Configurations' hasBullet />
					)}

					{currentUserInfo.can_read_reasons && <SidebarMenuItem to='cms/reject-reasons' title='Reasons' hasBullet />}
					{currentUserInfo.can_read_configuration && (
						<SidebarMenuItem to='cms/terms-and-conditions' title='Terms And Conditions' hasBullet />
					)}
				</SidebarMenuItemWithSub>
			)}

			{(currentUserInfo?.can_read_user || currentUserInfo?.can_read_role) && (
				<SidebarMenuItemWithSub
					to='/crafted/accounts'
					title='User Managements'
					icon={UsersSVG}
					fontIcon='bi-speedometer2'
				>
					{currentUserInfo?.can_read_user && (
						<SidebarMenuItem to='/users' title='Users' fontIcon='bi-speedometer2' hasBullet />
					)}

					{currentUserInfo?.can_read_role && (
						<SidebarMenuItem to='/roles' title='User Roles' fontIcon='bi-speedometer2' hasBullet />
					)}
				</SidebarMenuItemWithSub>
			)}
		</>
	);
};

export {SidebarMenuMain};
