import {Link} from 'react-router-dom';
import IllustrationSVG from '../../../../../assets/images/auth/illustration.svg';
// import LogoSVG from '../../../../../assets/images/logo.svg';
import LogoSVG from '../../../../../assets/images/polygon-logo.svg';

export default function Illustration() {
	return (
		<div className='d-flex flex-column flex-center justify-content-between pt-36 pb-0 px-5 px-md-15 w-100'>
			<div className='d-flex flex-column flex-center'>
				<div className='bg-white rounded-5 p-4 mb-12'>
					<Link to='/'>
						<img alt='Logo' src={LogoSVG} className='h-75px' />
					</Link>
				</div>

				<h1 className='text-white fs-2hx fw-bold text-center mb-7'>Welcome to QC portal for merchant onboarding</h1>
			</div>
			<img className='mx-auto w-275px w-md-50 w-xl-500px' src={IllustrationSVG} alt='' />
		</div>
	);
}
