import FilterButton from './FilterButton';
import {UserFiltersType} from '../Types';
import {useAuth} from '../../auth';

interface Props {
	getActiveButton: () => UserFiltersType;
	setActiveButton: (buttonTitle: UserFiltersType) => void;
}

const UserFilters = ({getActiveButton, setActiveButton}: Props) => {
	const {currentUser} = useAuth();
	const handleClick = (buttonTitle: UserFiltersType) => {
		setActiveButton(buttonTitle);
	};

	return (
		<div className='d-flex justify-content-start mb-5'>
			<div onClick={() => handleClick(UserFiltersType.all_user)} role='button' tabIndex={0}>
				<FilterButton
					title={UserFiltersType.all_user}
					isActive={getActiveButton() === UserFiltersType.all_user}
					optional='me-3 p-3'
				/>
			</div>
			{currentUser?.info.role !== 'ECAD' && (
				<>
					<div onClick={() => handleClick(UserFiltersType.qc_pool_1)} role='button' tabIndex={0}>
						<FilterButton
							title={UserFiltersType.qc_pool_1}
							isActive={getActiveButton() === UserFiltersType.qc_pool_1}
							optional='me-3 p-3'
						/>
					</div>
					<div onClick={() => handleClick(UserFiltersType.qc_pool_2)} role='button' tabIndex={0}>
						<FilterButton
							title={UserFiltersType.qc_pool_2}
							isActive={getActiveButton() === UserFiltersType.qc_pool_2}
							optional='me-3 p-3'
						/>
					</div>
				</>
			)}
		</div>
	);
};

export default UserFilters;
