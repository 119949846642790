import {FC, useState} from 'react';
import {useIntl} from 'react-intl';
// import useReady from '../../common/hooks/useReady';
import {PageTitle} from '../../common/providers/PageDataProvider';
import useDashboard from './hooks/useDashboard';
import ProductStatusCard from './partials/ProductStatusCard';
import {SlaArea} from './partials/SlaArea';
import UserFilters from './partials/UserFilters';
import UserStatus from './partials/UserStatus';
import {StatusCountsByProduct, UserFiltersType} from './Types';
import ProductCountCard from './partials/ProductCountCard';
import {useAuth} from '../auth';

const Dashboard: FC = () => {
	const [activeButton, setActiveButton] = useState(UserFiltersType.all_user);
	const {currentUser} = useAuth();

	const getActiveButton = () => {
		return activeButton;
	};

	const intl = useIntl();

	const {data} = useDashboard();

	if (!data) return null;

	const {product_counts: productCounts, status_counts: statusCounts, sla} = data;

	const getCurrentStatusFilter = (): StatusCountsByProduct => {
		const currentFilter = getActiveButton();
		if (currentFilter === UserFiltersType.qc_pool_1) {
			return statusCounts.qc_pool1;
		}
		if (currentFilter === UserFiltersType.qc_pool_2) {
			return statusCounts.qc_pool2;
		}
		return statusCounts.all;
	};

	return (
		<>
			<PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
			<div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
				<div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
					<UserStatus qcStatusCount={data.qc_status_data} />
				</div>

				<div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
					<UserFilters setActiveButton={setActiveButton} getActiveButton={getActiveButton} />
					<div className='row mb-0'>
						<div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
							<ProductCountCard counters={productCounts} />
						</div>
						<div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
							<ProductStatusCard product='PRA' counters={getCurrentStatusFilter().pra} />
						</div>
					</div>

					<div className='row mt-0'>
						<div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
							<ProductStatusCard product='LITE A' counters={getCurrentStatusFilter().lite_a} />
						</div>
						<div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
							<ProductStatusCard product='MEDIUM' counters={getCurrentStatusFilter().medium} />
						</div>
						<div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
							<ProductStatusCard product='LITE B' counters={getCurrentStatusFilter().lite_b} />
						</div>
					</div>
				</div>

				<div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
					{currentUser?.info.role !== 'ECAD' && <SlaArea sla={sla} />}
				</div>
			</div>
		</>
	);
};

export {Dashboard};
